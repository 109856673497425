.agent_register_calendar {
    .MuiPickersDay-root{
        background-color: #84cc6e;
        color: rgb(0, 0, 0);
    }

    .MuiPickersDay-root:hover{
        background-color: #1976d2;
        color: rgb(255, 255, 255);
    }

    .Mui-disabled{
        background-color: transparent;
        color: #fff;
    }

    div[role="row"]{
        justify-content: space-between;
    }

    .PrivatePickersFadeTransitionGroup-root > div > div:first-child{
        justify-content: space-between;
    }

    div:first-child {
        padding: 0px;
    }

}

